declare module 'o365.pwa.declaration.sw.FileCrudHandler.d.ts' {
    export type HTTPCONTENTRANGE = {
        unit: string;    // In most cases, this is "bytes"
        start: number;   // The start byte position
        end: number;     // The end byte position
        total: number;   // The total size of the resource
    };

    export interface FileStoreRecord {
        primKey: string;
        mimeType: string;
        data: ArrayBuffer | Blob;
        filesize: number;
        filename: string;
        extension: string;
        pdfRef?: string;
        thumbnailRef?: string;
        originalRef?: string;
        appID: string;
        CRC: number | null;
        // Add other properties as needed
    }

    export interface ViewOptions {
        FileRef: string;
    }

    export interface DownloadOptions {
        FileRef: string;
    }

    export interface UploadOptions {
        PrimKey?: string;
        Data: ArrayBuffer | Blob;
        MimeType: string;
        FileName: string;
        FileSize: number;
        Extension: string;
        PdfRef?: string;
        ThumbnailRef?: string;
        OriginalRef?: string;
        appID: string;
    }

    export interface ChunkUploadOptions {
        data: Blob;
        filename: string;
        extension: string;
        mimeType: string;
        extraValues: {
            [key: string]: unknown;
        };
        uploadRef?: string;
        appID: string;
    }

    export type ChunkUploadResponse = {
        action: string;
        fileRef: string | null;
        uploadRef: string;
        chunks: Array<{
            ChunkCRC: number | null;
            ChunkGuid: string;
            ChunkPosition: number;
            ChunkSize: number;
            Chunk_ID: number;
        }>
    };

    export interface PdfViewOptions {
        FileRef: string;
    }

    export interface FileUpdateOptions {
        OriginalRecord: FileStoreRecord;
        NewValues: FileStoreRecord;
    }

    // Define the FileCrudHandler type
    export interface FileCrudHandler {
        handleView: (options: ViewOptions) => Promise<FileStoreRecord | null>;
        handleDownload: (options: DownloadOptions) => Promise<FileStoreRecord | null>;
        handleUpload: (options?: UploadOptions, primkey?: string, appID?: string) => Promise<FileStoreRecord | null>;
        handleChunkUpload: (options: ChunkUploadOptions, headers: Map<string, Set<string>>) => Promise<ChunkUploadResponse>;
        handleChunkSetCRC: (options: { FileCRC: number; FileName: string; FileSize: number; UploadRef: string; ViewName: string; }) => Promise<{ action: string; }>;
        handlePdfView: (options: PdfViewOptions) => Promise<FileStoreRecord | null>;
        handlePdfDownload: (options: PdfViewOptions) => Promise<FileStoreRecord | null>;
        handleFileUpdate: (OriginalRecord: FileStoreRecord, NewValues: FileStoreRecord) => Promise<FileStoreRecord | null>;
        combineBlobs: (blob1: Blob | ArrayBuffer, blob2: Blob) => Promise<Blob>;
        blobToArrayBuffer: (blob: Blob) => Promise<ArrayBuffer>;
        getHeaderValues: (key: string, headers: Map<string, Set<string | number>>) => string | number | null;
        getContentRange: (contentString: string | null) => HTTPCONTENTRANGE | null;
    }

    export const FileCrudHandler: FileCrudHandler;
}

export { }
